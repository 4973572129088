import {
  checkoutDataToQuery,
  encodeBase64Url,
  integrationDataToQuery,
  thankYouDataToQuery,
} from '@wix/pricing-plans-router-utils';
import type { SubPage } from '../types/common';
import { integrationDataToAppSectionParams } from './integrationData';

type PageSectionId = 'Checkout' | 'Thank You' | 'Paywall' | 'membership_plan_picker_tpa';

export const PageSectionIdMap: Record<SubPage['name'], PageSectionId> = {
  checkout: 'Checkout',
  status: 'Thank You',
  restricted: 'Paywall',
  list: 'membership_plan_picker_tpa',
};

export const getSectionId = (
  subPageName: SubPage['name'],
  { isMultiPageApp }: { isMultiPageApp: boolean },
): PageSectionId => (isMultiPageApp ? PageSectionIdMap[subPageName] : PageSectionIdMap.list);

export const getSubPagePath = (subPage: SubPage, { isMultiPageApp }: { isMultiPageApp: boolean }) =>
  isMultiPageApp ? subPageToPathMPA(subPage) : subPageToPathSPA(subPage);

function subPageToPathMPA(subPage: SubPage): string {
  const addQueryStringPrefix = (path: string): `?${string}` | '' => (path !== '' ? `?${path}` : '');
  switch (subPage.name) {
    case 'checkout':
      return addQueryStringPrefix(checkoutDataToQuery(subPage.checkoutData).toString());
    case 'status':
      return addQueryStringPrefix(thankYouDataToQuery(subPage.statusData).toString());
    case 'restricted':
      return '/';
    default:
    case 'list':
      return addQueryStringPrefix(integrationDataToQuery(subPage.integrationData).toString());
  }
}

function subPageToPathSPA(subPage: SubPage): string {
  switch (subPage.name) {
    case 'checkout':
      return '/payment/' + encodeBase64Url(subPage.checkoutData);
    case 'status':
      return `/status/${encodeBase64Url(subPage.statusData)}`;
    case 'restricted':
      return '/restricted';
    default:
    case 'list':
      return integrationDataToAppSectionParams(subPage.integrationData).toString();
  }
}
